<template>
  <div class="editor">
    <div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="文章标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="简介" >
          <el-input v-model="form.blurb" :maxlength="60"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="display: flex;flex-wrap: wrap;justify-content: space-between">
      <div style="border: 1px solid #ccc;display: inline-block;width: 48%">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        />
      </div >
      <div v-html="html" style="border: 1px solid #ccc;display: inline-block;width: 48%">
      </div>
      <el-button type="success" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default Vue.extend({
  name: "MyEditor",
  components: { Editor, Toolbar },
  data() {
    // const url = 'http://localhost:8088'
    const url = 'https://wook.msskkk.cn/hero'
    return {
      url:url,
      form:{},
      rules:{
        title : [
          {required: true, message: '不能为空', trigger: 'blur'}
        ]
      },
      editor: null,
      html:'',
      toolbarConfig: { },
      editorConfig: {
        MENU_CONF: {
          uploadImage:{
            server: url+'/attachment/upload/image/article',
            fieldName: 'file',
            maxFileSize:100*1024*1024
          },
          uploadVideo:{
            server: url+'/attachment/upload/video/article',
            fieldName: 'video',
            maxFileSize:100*1024*1024*1024
          }
        },
      },
      mode: 'default', // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    submit(){
      this.$refs['form'].validate(valid => {
        if(valid){
          if (this.isNotEmptyStr( this.html )){
            this.form.content = this.html
            // console.log(this.form)
            this.$axios({
              method: 'POST',
              url:this.url+"/article",
              data:this.form,
              responseType:'json'
            }).then(res=>{
              // console.log(res.data)
              if ( res.data.code == 200){
                this.form = {}
                this.html =""
                this.$message({
                  message:'保存文章成功',
                  type:'success',
                })
              }
            })
          }else {
            this.$message({
              message:'请正确编辑文章内容',
              type: 'warning'
            })
          }

        }
      })
    },
    isNotEmptyStr(str){
      if( typeof str == "string" && str.length > 0){
        return true
      }
      return false
    },

  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>