<template>
<div>
  <!--文件上传入口-->
  <el-upload
      style=""
      :action="uploadImageAddress"
      name="video"
      ref="upload"
      :before-upload="beforeAvatarUpload"
      :on-exceed="handleExceed"
      :on-success="uploadViewSuccess"
      :show-file-list="false"
  >
    <el-button size="small" type="primary">选择视频</el-button>
    <div slot="tip" class="el-upload__tip"><span style="color: red;font-size: 16px;margin-left: 5px">*</span>仅支持上传mp4格式文件</div>
  </el-upload>

</div>
</template>

<script>
export default {
  name: "VideoComponent",
  data(){
    return{
      baseUrl:'',
      uploadImageAddress:"",
    }
  },
  created() {
    this.baseUrl = process.env.VUE_APP_BASE_API
    this.uploadImageAddress = process.env.VUE_APP_BASE_API + '/attachment/upload/video/education'
  },
  methods:{
    beforeAvatarUpload(file){
      // const isMP4 = file.type === 'video/mp4';
      const isLt2G = file.size / 1024 / 1024 < 2*1024;
      // if (!isMP4) {
      //   this.$message.error('视频格式只能是 MP4 格式!');
      // }
      if (!isLt2G) {
        this.$message.error('上传大小不能超过 2GB!');
      }
      // return isMP4 && isLt2G;
      return isLt2G;
    },
    handleExceed(){

    },
    uploadViewSuccess(res, file){
      // console.log(res)
      if(res.code == 200){
        this.$message({
          message:"上传成功" ,
          type: "success"
        })
      }
      console.log(file)
    },
  }
}
</script>

<style scoped>

</style>