<template>
<div>
  <el-form ref="form" :model="form" label-width="100px">

    <el-form-item label="缩略图" prop="thumbnailLink">
      <el-upload
          name="attach"
          :action="baseUrl+'/attachment/upload/seekKinsfolk/image'"
          list-type="picture-card"
          :show-file-list="false"
          :on-success="handleThumbnailImageSuccess"
          :before-upload="beforeUpload"
          :on-remove="removeUploadThumbnailPic"
          :before-remove="beforeRemoveUploadPic"
          :on-exceed="handleExceed">
        <img v-if="thumbnailUrl" :src="thumbnailUrl" style="height: 100%" class="avatar">
        <i class="el-icon-plus"></i>
      </el-upload>
    </el-form-item>

    <el-form-item label="详情图片" prop="detailsLink">
      <el-upload
          name="attach"
          :action="baseUrl+'/attachment/upload/seekKinsfolk/image'"
          list-type="picture-card"
          :on-success="handleDetailsImageSuccess"
          :before-upload="beforeUpload"
          :on-remove="removeUploadDetailsPic"
          :before-remove="beforeRemoveUploadPic"
          :on-exceed="handleExceed"
          :limit="limitUploadPic"
          :file-list="detailsImageUrlList"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>

export default {
  name: "SeekKinsfolkComponent",
  data(){
    return{
      baseUrl:'',
      form:{},
      thumbnailUrl:'',
      limitUploadPic:10,
      detailsImageUrlList:[],
    }
  },
  created() {
    // this.baseUrl = 'http://localhost:8088'
    this.baseUrl = '/hero'
  },
  methods:{
    handleThumbnailImageSuccess(res,file){
      this.thumbnailUrl = res.data.url
      // this.thumbnailUrl ='https://wook.msskkk.cn/static/image/thumbnail/d2e00894-ad1e-423b-916f-81f89a47e641.png'
      this.form.thumbnailLink = res.data.url
      if (res.code != 200) {
        console.error(res.msg)
      }
    },
    beforeUpload(file){
      // const isJPG = file.type === 'image/jpeg';
      const isLt15M = file.size / 1024 / 1024 < 15;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt15M) {
        this.$message.error('上传图片大小不能超过 15MB!');
      }
      // return isJPG && isLt15M;
      return isLt15M;
    },
    removeUploadThumbnailPic(file, fileList){
      this.thumbnailUrl=''
    },
    handleExceed(files, fileList){

    },
    handleDetailsImageSuccess(res,file){
      if (res.code != 200) {
        console.error(res.msg)
        this.$message({
          message:'上传失败',
          type:'error'
        })
        return
      }
      let idx = this.detailsImageUrlList.length + 1;
      let obj = {
        uid: idx,
        name: '图片 ' + idx,
        url: res.data.url,
      };
      this.detailsImageUrlList.push(obj)
    },
    removeUploadDetailsPic(file, fileList){
      const objKeyArr = Object.keys(this.detailsImageUrlList)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.detailsImageUrlList[objKeyArr[i]]
            && this.detailsImageUrlList[objKeyArr[i]].uid === file.uid) {
          this.detailsImageUrlList.splice(objKeyArr[i], 1)
          return
        }
      }
    },
    beforeRemoveUploadPic(file, fileList){
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    onSubmit(){
      if(this.detailsImageUrlList.length < 1){
        this.$message({
          message: '请上传证明图',
          type: 'warning'
        })
        return
      }
      this.form.detailsLink = this.detailsImageUrlList.map(item => item.url).filter(str => str && str.trim()).join(',');

      this.$axios({
        method: 'POST',
        url:this.baseUrl+"/seek/heroic/kinsfolk",
        data:this.form,
        responseType:'json'
      }).then( res=>{
        console.log(res.data)
        if(res.data.code == 200){
          this.$message({
            message:"上传寻烈属成功",
            type:"success"
          })
          this.reset();
        }
      })
    },
  },
}
</script>

<style scoped>

</style>