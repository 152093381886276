<template>
  <div class="hello">
    <el-form ref="form" :model="form" label-width="100px" :rules="rules">

      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="级别" >
        <el-select v-model="form.grade" placeholder="请选择级别">
          <el-option label="烈士" value="1"></el-option>
          <el-option label="其他" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="陵园" >
        <el-input v-model="form.heroTombs"></el-input>
      </el-form-item>

      <el-form-item label="安葬地址" >
        <el-input v-model="form.burialAddress"></el-input>
      </el-form-item>

      <el-form-item label="鲜花数量" >
        <el-input v-model="form.flowerNumber" type="number"></el-input>
      </el-form-item>

      <el-form-item label="时间" >
        <el-col :span="11">
          <el-date-picker type="date" placeholder="选择日期" v-model="form.startTime" style="width: 100%;"></el-date-picker>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="11">
          <el-date-picker type="date" placeholder="选择日期" v-model="form.endTime" style="width: 100%;"></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item label="缩略图" prop="thumbnail">
        <el-upload
            name="attach"
            :action="thumbnailImageUpLoadUrl"
            list-type="picture-card"
            :on-success="handleThumbnailImageSuccess"
            :before-upload="beforeUpload"
            :on-remove="removeUploadThumbnailPic"
            :before-remove="beforeRemoveUploadPic"
            :on-exceed="handleExceed"
            :file-list="thumbnailImageUrlList">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="主图/封面图" prop="mainImageLink">
        <el-upload
            name="attach"
            :action="mainImageUpLoadUrl"
            list-type="picture-card"
            :on-success="handleMainImageSuccess"
            :before-upload="beforeUpload"
            :on-remove="removeUploadMainPic"
            :before-remove="beforeRemoveUploadPic"
            :on-exceed="handleExceed"
            :file-list="mainImageUrlList">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="描述图" prop="descriptionImageLink">
        <el-upload
            name="attach"
            :action="descriptionImageUpLoadUrl"
            list-type="picture-card"
            :on-success="handleDescriptionImageSuccess"
            :before-upload="beforeUpload"
            :on-remove="removeUploadDescriptionPic"
            :before-remove="beforeRemoveUploadPic"
            :on-exceed="handleExceed"
            :file-list="descriptionImageUrlList">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="简介" >
        <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.blurb"
            maxlength="512"
            show-word-limit
        >
        </el-input>
      </el-form-item>

    </el-form>
    <el-button type="success" @click="submit">提交</el-button>
  </div>
</template>

<script>
export default {
  name: 'HeroComponent',
  props: {
    msg: String
  },
  data(){
    // const url = 'http://localhost:8088'
    const url = 'https://wook.msskkk.cn/hero'
    return{
      url:url,
      thumbnailImageUrlList:[],
      thumbnailImageUpLoadUrl:url+"/attachment/upload/image/thumbnail",
      thumbnailImageUrl:'',
      mainImageUrlList:[],
      mainImageUpLoadUrl:url+"/attachment/upload/image/main",
      mainImageUrl:'',
      descriptionImageUrlList:[],
      descriptionImageUpLoadUrl:url+"/attachment/upload/image/description",
      descriptionImageUrl:'',
      form:{},
      rules: {
        name: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ]
      },
    }
  },
  created() {

  },
  methods:{
    handleThumbnailImageSuccess(res, file) {
      this.thumbnailImageUrl = URL.createObjectURL(file.raw);
      if (res.code != 200) {
        console.error(res.msg)
      }
      let idx = this.thumbnailImageUrlList.length + 1;
      let obj = {
        uid: idx,
        name: '图片 ' + idx,
        url: res.data.url,
      };
      this.thumbnailImageUrlList.push(obj)
    },
    handleMainImageSuccess(res, file) {
      this.mainImageUrl = URL.createObjectURL(file.raw);
      if (res.code != 200) {
        console.error(res.msg)
      }
      let idx = this.mainImageUrlList.length + 1;
      let obj = {
        uid: idx,
        name: '图片 ' + idx,
        url: res.data.url,
      };
      this.mainImageUrlList.push(obj)
      // this.mainImageUrl = URL.createObjectURL(file.raw);
    },
    handleDescriptionImageSuccess(res, file) {
      if (res.code != 200) {
        console.error(res.msg)
      }
      let idx = this.descriptionImageUrlList.length + 1;
      let obj = {
        uid: idx,
        name: '图片 ' + idx,
        url: res.data.url,
      };
      this.descriptionImageUrlList.push(obj)
      // this.descriptionImageUrl = URL.createObjectURL(file.raw);
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt15M = file.size / 1024 / 1024 < 15;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt15M) {
        this.$message.error('上传图片大小不能超过 15MB!');
      }
      // return isJPG && isLt15M;
      return isLt15M;
    },
    beforeRemoveUploadPic(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    removeUploadThumbnailPic(file, fileList){
      this.removeUploadPic(file.uid,this.thumbnailImageUrlList)
    },
    removeUploadMainPic(file, fileList) {
      this.removeUploadPic(file.uid,this.mainImageUrlList)
    },
    removeUploadDescriptionPic(file, fileList) {
      this.removeUploadPic(file.uid,this.descriptionImageUrlList)
    },
    removeUploadPic(id, list){
      const objKeyArr = Object.keys(list)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (list[objKeyArr[i]]
            && list[objKeyArr[i]].uid === id) {
          list.splice(objKeyArr[i], 1)
          return
        }
      }
    },
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 ${this.limitUploadPic} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    submit(){
      this.$refs['form'].validate(valid => {
        if (valid) {
          if(this.thumbnailImageUrlList.length < 1){
            this.$message({
              message: '请上传缩略图',
              type: 'warning'
            })
            return
          }
          this.form.thumbnail = this.thumbnailImageUrlList.map(item => item.url).filter(str => str && str.trim()).join(',');


          if(this.mainImageUrlList.length < 1){
            this.$message({
              message: '请上传主图',
              type: 'warning'
            })
            return
          }
          this.form.mainImageLink = this.mainImageUrlList.map(item => item.url).filter(str => str && str.trim()).join(',');
          if(this.descriptionImageUrlList.length < 1){
            this.$message({
              message: '请上传详情图',
              type: 'warning'
            })
            return
          }
          this.form.descriptionImageLink = this.descriptionImageUrlList.map(item => item.url).filter(str => str && str.trim()).join(',');

          this.$axios({
            method: 'POST',
            url:this.url+"/heroic",
            data:this.form,
            responseType:'json'
          }).then(res=>{
            // console.log(res.data)
            if(res.data.code  == 200){
              this.$message({
                message:'上传完成',
                type:'success'
              })
              location.reload()
            }
          })
        }
      })

    },
    // 表单重置
    reset() {
      this.form = {};
      this.mainImageUrlList=[]
      this.descriptionImageUrlList=[]
      this.thumbnailImageUrlList=[]
      this.resetForm("form");
    },
    resetForm() {
      this.$refs['form'].resetFields()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
