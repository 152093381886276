<template>
<div>
  <el-form ref="form" :model="form" label-width="100px">

    <el-form-item label="烈士照片" prop="heroImageLink">
      <el-upload
          name="attach"
          :action="baseUrl+'/attachment/upload/seek/image'"
          list-type="picture-card"
          :show-file-list="false"
          :on-success="handleMainImageSuccess"
          :before-upload="beforeUpload"
          :on-remove="removeUploadMainPic"
          :before-remove="beforeRemoveUploadPic"
          :on-exceed="handleExceed">
        <img v-if="heroImageUrl" :src="heroImageUrl" style="height: 100%" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-form-item>

    <el-form-item label="烈士姓名">
      <el-input v-model="form.heroName"></el-input>
    </el-form-item>

    <el-form-item label="烈士性别" prop="heroGender">
      <el-radio-group v-model="form.heroGender">
        <el-radio label="1">男</el-radio>
        <el-radio label="0">女</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="烈士籍贯" prop="heroNativePlace">
      <el-cascader
          :options="areaSelectData"
          @change="handleChange"
          class="full-width"
          size="large"
          v-model="form.heroNativePlace"
          placeholder="请选择"
      />
    </el-form-item>

    <el-form-item label="烈士安葬地">
      <el-input v-model="form.heroBurialAddress"></el-input>
    </el-form-item>

    <el-form-item label="烈士出生年月">
      <el-date-picker type="month" placeholder="选择月" value-format="yyyy-MM" v-model="form.heroBirthday" style="width: 100%;"></el-date-picker>
    </el-form-item>

    <el-form-item label="参加革命日期">
      <el-date-picker type="month" placeholder="选择月" value-format="yyyy-MM" v-model="form.heroJoinRevolution" style="width: 100%;"></el-date-picker>
    </el-form-item>

    <el-form-item label="原部队/单位">
      <el-input v-model="form.heroArmyName"></el-input>
    </el-form-item>

    <el-form-item label="证明材料" prop="evidenceFileLink">
      <el-upload
          name="attach"
          :action="baseUrl+'/attachment/upload/seek/image'"
          list-type="picture-card"
          :on-success="handleEvidenceImageSuccess"
          :before-upload="beforeUpload"
          :on-remove="removeUploadEvidencePic"
          :before-remove="beforeRemoveUploadPic"
          :on-exceed="handleExceed"
          :limit="limitUploadPic"
          :file-list="evidenceImageUrlList"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-form-item>

    <el-form-item label="上传人名字">
      <el-input v-model="form.providerName"></el-input>
    </el-form-item>

    <el-form-item label="上传人电话">
      <el-input v-model="form.providerPhone"></el-input>
    </el-form-item>

    <el-form-item label="其他描述信息">
      <el-input type="textarea" v-model="form.otherInfo"></el-input>
    </el-form-item>

    <el-form-item label="发布时间">
      <el-date-picker type="date" placeholder="选择日期" v-model="form.publishDate" style="width: 100%;"></el-date-picker>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data"

export default {
  name: "SeekComponent",
  data(){
    return{
      baseUrl:'',
      form:{},
      heroImageUrl:'',
      addr:"",
      limitUploadPic:3,
      evidenceImageUrlList:[],
      areaSelectData: regionData,//省市区三级联动数据
    }
  },
  created() {
    this.baseUrl = '/hero'
  },
  methods:{

    handleChange(value) {
      //value为省市区code数组
      if (value) {
        var provinceCode = CodeToText[value[0]];//code转为省
        var cityCode = CodeToText[value[1]];//市
        var orgion = CodeToText[value[2]];//区
        this.addr = provinceCode+'/'+cityCode+'/'+orgion
      }
    },

    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt15M = file.size / 1024 / 1024 < 15;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt15M) {
        this.$message.error('上传图片大小不能超过 15MB!');
      }
      // return isJPG && isLt15M;
      return isLt15M;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limitUploadPic} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemoveUploadPic(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    removeUploadMainPic(file, fileList){

    },
    removeUploadEvidencePic(file, fileList){
      this.removeUploadPic(file.uid,this.evidenceImageUrlList)
    },
    removeUploadPic(id, list){
      const objKeyArr = Object.keys(list)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (list[objKeyArr[i]]
            && list[objKeyArr[i]].uid === id) {
          list.splice(objKeyArr[i], 1)
          return
        }
      }
    },
    handleMainImageSuccess(res,file){
      this.heroImageUrl = res.data.url
      this.form.heroImageLink = res.data.url
      if (res.code != 200) {
        console.error(res.msg)
      }
    },
    handleEvidenceImageSuccess(res,file){
      if (res.code != 200) {
        console.error(res.msg)
        this.$message({
          message:'上传失败',
          type:'error'
        })
        return
      }
      let idx = this.evidenceImageUrlList.length + 1;
      let obj = {
        uid: idx,
        name: '图片 ' + idx,
        url: res.data.url,
      };
      this.evidenceImageUrlList.push(obj)
    },




    onSubmit(){
      this.form.heroNativePlace = this.addr
      if(this.evidenceImageUrlList.length < 1){
        this.$message({
          message: '请上传证明图',
          type: 'warning'
        })
        return
      }
      this.form.evidenceFileLink = this.evidenceImageUrlList.map(item => item.url).filter(str => str && str.trim()).join(',');
      this.$axios({
        method: 'POST',
        url:this.baseUrl+"/seek/heroic",
        data:this.form,
        responseType:'json'
      }).then( res=>{
        console.log(res.data)
        if(res.data.code == 200){
          this.$message({
            message:"上传寻找烈士成功",
            type:"success"
          })
          this.reset();
        }
      })
    },
    // 表单重置
    reset() {
      this.form = {};
      this.heroImageUrl =''
      this.evidenceImageUrlList=[]
    },
  }
}
</script>

<style scoped>

</style>