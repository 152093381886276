<template>
  <div class="home">
    <el-tabs type="border-card">
      <el-tab-pane label="英雄" >
        <HeroComponent msg="英雄数据填报"/>
      </el-tab-pane>

      <el-tab-pane label="文章">
        <ArticleComponent />
      </el-tab-pane>

<!--      <el-tab-pane label="寻亲">-->
<!--        <SeekComponent />-->
<!--      </el-tab-pane>-->

      <el-tab-pane label="寻烈属">
        <SeekKinsfolkComponent />
      </el-tab-pane>

      <el-tab-pane label="纪念设施">
        <MemorialVenueComponent />
      </el-tab-pane>

      <el-tab-pane label="视频">
        <VideoComponent />
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroComponent from '@/components/HeroComponent.vue'
import VideoComponent from '@/components/VideoComponent.vue'
import ArticleComponent from '@/components/ArticleComponent.vue'
import SeekComponent from '@/components/SeekComponent'
import SeekKinsfolkComponent from '@/components/SeekKinsfolkComponent'
import MemorialVenueComponent from '@/components/MemorialVenueComponent'

export default {
  name: 'HeroView',
  components: {
    HeroComponent,VideoComponent,ArticleComponent,SeekComponent,SeekKinsfolkComponent,MemorialVenueComponent
  }
}
</script>
