<template>
  <div>
<!--    <img src="https://wook.msskkk.cn/static/image/main/daead6e9-bf3c-4fe4-83ea-e950384d304b.jpg" />-->
    <MyEditor />
  </div>
</template>


<script>
import MyEditor from "./MyEditor"
export default {
  name: "ArticleComponent",
  components:{
    MyEditor
  }
}
</script>
<style scoped>

</style>